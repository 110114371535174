<template>
  <div class="coin">
    <AppIcon :name="selectedCoin?.icon" size="28px" style="margin-right: 12px;" />
    <AppText size="15px" class="font-medium">
      {{ selectedCoin.name }}
    </AppText>
    <FSpacer />
    <AppText size="12px" :opacity="0.4" type="uppercase">
      {{ value === 'ETHEREUM' ? 'ETH' : value }}
    </AppText>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue';

import { fullCoinsArray } from '@/common/data';

export default defineComponent({
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const selectedCoin = computed(() => {
      const propCoin = props.value === 'ETHEREUM' ? 'ETH_ETH' : props.value;
      return fullCoinsArray.find((coin) => coin.value === propCoin);
    });

    return { selectedCoin };
  },
});
</script>

<style scoped lang="scss">
.coin {
  display: flex;
  align-items: center;

  border: 1px solid var(--color-black-01);
  border-radius: 6px;
  padding: 10px 16px;
  background: var(--color-white);

  width: 190px;
}
</style>
