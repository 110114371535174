<template>
  <div
    class="family-selection"
    :class="{
      'is-active': $attrs.modelValue,
      'is-disabled': isDisabled,
    }"
  >
    <div class="d-flex align-items-center" style="margin-bottom: 10px;">
      <FSwitch v-bind="$attrs" style="margin-right: 16px;" />
      <AppText size="16px" class="font-medium" :color="$attrs.modelValue ? 'var(--color-primary)' : 'var(--color-black)'">
        <slot name="title" />
      </AppText>
    </div>
    <AppText :opacity="0.5">
      <slot name="description" />
    </AppText>
    <div class="coins-wrapper" style="margin-top: 16px;">
      <slot />
    </div>
    <slot name="extra" />
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {};
  },
});
</script>

<style scoped lang="scss">
.family-selection {
  border: 1px solid var(--color-black-01);
  box-shadow: 0px 2px 5px var(--color-black-01);
  border-radius: 6px;
  padding: 20px;

  margin-bottom: 20px;

  background: var(--color-white);
  border-color: var(--color-black-01);
  @include transition-base;

  &.is-active {
    background: var(--color-primary-003);
    border-color: var(--color-primary);
  }
}

.coins-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 18px 10px;
}

.is-disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>
