<template>
  <AppText
    type="uppercase"
    variant="div"
    :opacity="0.4"
    mb="10px"
    class="font-medium"
  >
    <slot name="title">
      {{ t('merchantWallet.label.seedPhrase') }}
    </slot>
  </AppText>
  <div class="text-container">
    <div ref="seed" class="text-wrap">
      <AppText
        size="18px"
        :line-height="1.65"
        class="font-mono"
      >
        <slot />
      </AppText>
    </div>

    <FButton
      class="download-icon"
      icon="download-colored"
      is-icon
      type="plain"
      @click="onSaveSeed"
    />

    <CoinIcons
      :coins="coins"
      is-disabled
      size="32px"
    />
  </div>
</template>

<script setup>
import { ref } from 'vue';

import { downloadAsFile } from '@/utils/functions';
import { i18n } from '@/plugins/localization';

import CoinIcons from './CoinIcons.vue';

defineProps({
  coins: {
    type: Array,
    default: () => [],
  },
});

const seed = ref(null);

const { t } = i18n.global;

const onSaveSeed = () => {
  const textFromSlot = seed.value ? seed.value.innerText : '';
  downloadAsFile({
    data: textFromSlot,
    filename: t('merchantWallet.file.phraseSwitch'),
  });
};
</script>

<style scoped lang="scss">
.text-container {
  padding: 12px 24px 20px;
  margin-bottom: 30px;

  background: var(--color-F7F7F7);
  border-radius: 4px;

  position: relative;
}

.text-wrap {
  margin-bottom: 36px;
  padding-right: 40px;
}

.download-icon {
  position: absolute;
  top: 12px;
  right: 14px;
}
</style>
