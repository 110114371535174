<template>
  <div class="extra-coins-row" :class="{ control: isControl, checked: isChecked }" @click.prevent="onToggle(!isChecked)">
    <AppCheckbox v-if="isControl" type="round" :model-value="isChecked" style="margin-right: 20px;" />

    <ExtraCoin
      v-for="(coin, i) in coins"
      :key="i"
      :coin="coin"
      :is-enabled="isChecked || !isControl"
    />
    <FSpacer />
    <div class="mark">
      <AppText size="12px" color="var(--color-black-04)" class="font-medium">
        {{ coins[0]?.family }}
      </AppText>
    </div>
  </div>
</template>

<script setup>
import AppCheckbox from '@/components/AppCheckbox.vue';

import ExtraCoin from './ExtraCoin.vue';

const emit = defineEmits(['toggle']);

const props = defineProps({
  coins: {
    type: Object,
    default: () => ({}),
  },
  isControl: {
    type: Boolean,
    default: false,
  },
});

const isChecked = defineModel({ type: Boolean, default: false });

const onToggle = (val) => {
  emit('toggle', props.coins[0]?.family, val);
};
</script>

<style lang="scss" scoped>
.extra-coins-row {
  display: flex;
  align-items: center;
  height: 48px;
  background-color: transparent;
  @include transition-base(background-color);

  .mark {
    width: 60px;
    height: 24px;
    background-color: var(--color-black-003);
    border-radius: 4px;
    @include flex-center;
  }

  &.control {
    border: 1px solid var(--color-black-01);
    border-radius: 8px;
    padding: 0 20px;

    &:hover {
      border-color: var(--color-primary-05);
      cursor: pointer;

      :deep(.app-checkbox .app-checkbox-control) {
        border-color: var(--color-primary-05);
      }
    }

    &.checked {
      border-color: var(--color-primary);
      background-color: var(--color-primary-01);
    }
  }
}
</style>
