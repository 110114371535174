<template>
  <FButton
    type="plain"
    size="x-small"
    class="back-button"
    style="padding-left: 8px !important"
  >
    <AppIcon
      name="arrow-bracket"
      class="rotate-270"
      size="18px"
      style="margin-right: 4px"
      fill="var(--color-black-06)"
    />
    <AppText size="14px" color="var(--color-black-06)">
      {{ t('common.back') }}
    </AppText>
  </FButton>
</template>

<style scoped lang="scss">
.back-button {
  border-radius: 24px;
  height: 30px;
  padding: 0 16px 0 8px;
  color: var(--color-black-06);
}
</style>
