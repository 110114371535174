<template>
  <AppText
    v-bind="$attrs"
    class="cancel"
    size="14px"
    color="var(--color-black-04)"
    @click="onClick"
  >
    {{ t('merchantWallet.generate.familySelection.button.cancel') }}
  </AppText>
</template>

<script>
import { emitter } from '@/composables/useBus';

export default {
  name: 'ResetButton',
  setup() {
    const onClick = () => {
      emitter.emit('reset-wallet-setup');
    };

    return { onClick };
  },
};
</script>

<style scoped lang="scss">
.cancel {
  cursor: pointer;
  @include transition-base('color');

  &:hover {
    opacity: 1;
    color: var(--color-error);
  }
}
</style>
