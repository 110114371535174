import { ref } from 'vue';

import { currentTab } from '@/composables/useWallet';

const BlockchainNetworks = {
  LTC: {
    messagePrefix: '\x19Litecoin Signed Message:\n',
    bech32: 'ltc',
    bip32: {
      public: 0x01b26ef6,
      private: 0x01b26792,
    },
    pubKeyHash: 0x30,
    scriptHash: 0x32,
    wif: 0xb0,
  },
  DASH: {
    messagePrefix: '\x19Dash Signed Message:\n',
    bech32: '',
    bip32: {
      public: 0x0488b21e, // 0x049d7cb2,
      private: 0x0488ade4, // 0x049d7878,
    },
    pubKeyHash: 0x4c,
    scriptHash: 0x10,
    wif: 0xcc,
  },
  BTC: {
    messagePrefix: '\x18Bitcoin Signed Message:\n',
    bech32: 'bc',
    bip32: {
      public: 0x049d7cb2,
      private: 0x0488ade4, // 76066276
    },
    pubKeyHash: 0,
    scriptHash: 5,
    wif: 128,
  },
  ETHEREUM: null,
  TRON: null,
  BSC: null,
};

const getBlockchainNetwork = async (coin) => {
  if (['ETHEREUM', 'TRON', 'BSC'].includes(coin)) {
    const { networks } = await import('bitcoinjs-lib');
    return networks.bitcoin;
  }
  return BlockchainNetworks[coin];
};

const NonCustodialWalletCurrencyPaths = {
  BTC: "m/49'/0'/0'",
  LTC: "m/49'/2'/0'",
  DASH: "m/44'/5'/0'",
  ETHEREUM: "m/44'/60'/0'",
  TRON: "m/44'/195'/0'",
  BSC: "m/44'/60'/0'",
};

export const bip39Family = ['BTC', 'LTC', 'ETHEREUM', 'DASH', 'BSC', 'TRON'];
export const bip39FamilySelection = ['BTC', 'LTC', 'ETHEREUM', 'DASH'];

const selectedCoinsFamily = ref(['bip39Family', 'moneroFamily']);
const password = ref('');

const bip39Mnemonic = ref('');
const bip39Seed = ref('');

const moneroMnemonic = ref('');
const moneroSeed = ref('');

const generateMoneroPublicKeys = async () => {
  const monero = await import('monero-js/dist');
  const moneroWallet = monero.wallet.fromSeed(moneroSeed.value);

  const prvVkey = moneroWallet.privateViewKey.toString('hex');
  const pubSkey = moneroWallet.publicSpendKey.toString('hex');
  const pubVkey = moneroWallet.publicViewKey.toString('hex');
  const primaryAddress = moneroWallet.primaryAddress().encode();

  return {
    prvVkey,
    pubSkey,
    pubVkey,
    primaryAddress,
  };
};

const generateBip39PublicKey = async (coin = 'BTC') => {
  const BIP32Factory = await import('bip32');
  const ecc = await import('tiny-secp256k1-browserify/lib');

  const bip32 = BIP32Factory.default(ecc);
  const network = await getBlockchainNetwork(coin);

  const root = bip32.fromSeed(bip39Seed.value, network);
  const path = NonCustodialWalletCurrencyPaths[coin];
  const child = root.derivePath(path);

  const xpub = child.neutered().toBase58();

  if (coin === 'ETHEREUM' || coin === 'BSC') {
    return child.publicKey;
  }

  if (coin === 'TRON') {
    const TronWeb = await import('tronweb/dist/TronWeb');

    const fullPath = `${path}/0/0`;
    const fullPathchild = root.derivePath(fullPath);
    const prvHex = fullPathchild.privateKey.toString('hex');
    const address = TronWeb.default.address.fromPrivateKey(prvHex);

    return address;
  }

  return xpub;
};

const generateEthereumPrimaryAddress = async (coin) => {
  const publicKeyToAddressModule = await import('ethereum-public-key-to-address');
  const publicKeyToAddress = publicKeyToAddressModule.default;

  const pubKey = await generateBip39PublicKey(coin);
  const address = publicKeyToAddress(pubKey);

  return address;
};

export const useWalletSetup = () => {
  const generateData = async () => {
    if (selectedCoinsFamily.value.includes('bip39Family')) {
      const bip39 = await import('bip39');
      bip39Mnemonic.value = bip39.generateMnemonic();
      bip39Seed.value = bip39.mnemonicToSeedSync(bip39Mnemonic.value, password.value);
    }
    if (selectedCoinsFamily.value.includes('moneroFamily')) {
      const monero = await import('monero-js/dist');
      await monero.wallet.initEcc();

      moneroMnemonic.value = monero.mnemonic.generateWithChecksum();
      moneroSeed.value = monero.mnemonic.toSeed(moneroMnemonic.value);
    }
  };

  const generateBip39Keys = () => bip39Family.map(async (coin) => {
    if (coin === 'ETHEREUM' || coin === 'BSC') {
      const primaryAddress = await generateEthereumPrimaryAddress(coin);
      return { coin, primaryAddress };
    }

    if (coin === 'TRON') {
      const primaryAddress = await generateBip39PublicKey(coin);
      return { coin, primaryAddress };
    }

    const pubKey = await generateBip39PublicKey(coin);
    return { coin, pubKey };
  });

  const generatePublicKeys = async () => {
    const publicKeys = {};

    if (selectedCoinsFamily.value.includes('bip39Family')) {
      publicKeys.bip39Family = await Promise.all(generateBip39Keys());
    }

    if (selectedCoinsFamily.value.includes('moneroFamily')) {
      publicKeys.xmrFamily = await generateMoneroPublicKeys();
      console.log(publicKeys.xmrFamily);
    }

    return publicKeys;
  };

  const clearState = () => {
    bip39Mnemonic.value = '';
    bip39Seed.value = '';
    password.value = '';
  };

  return {
    password,
    bip39Mnemonic,
    bip39Seed,
    moneroMnemonic,
    moneroSeed,

    generateData,

    selectedCoinsFamily,
    generatePublicKeys,

    clearState,

    currentTab,
  };
};
