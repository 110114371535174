<template>
  <div class="coin-icons">
    <template v-for="coin in coins" :key="coin">
      <AppTooltip
        :is-enabled="additionalNetworks.includes(coin)"
        text-align="center"
        cursor="default"
      >
        <template #text>
          <template v-if="coin === 'TRON'">
            {{ t('merchantWallet.label.supportedTronTokens') }}
          </template>
          <template v-if="coin === 'BSC'">
            {{ t('merchantWallet.label.supportedBscTokens') }}
          </template>
        </template>
        <template #default="{ isEnabled }">
          <AppIcon
            :name="`${coin.toLowerCase()}-${isEnabled ? 'dotted' : isDisabled ? 'disabled' : 'circle'}`"
            :size="size"
          />
        </template>
      </AppTooltip>
    </template>
  </div>
</template>

<script setup>
import { computed } from 'vue';

import AppTooltip from '@/components/AppTooltip.vue';

import { supportedNetworksFamily } from '@/common/data';

defineProps({
  coins: {
    type: Array,
    default: () => [],
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: '32px',
  },
});

const additionalNetworks = computed(() => supportedNetworksFamily.filter((family) => family !== 'ETHEREUM'));
</script>

<style lang="scss" scoped>
.coin-icons {
  display: flex;
  gap: 12px;
}
</style>
