<template>
  <div>
    <AppText
      variant="div"
      type="uppercase"
      :opacity="0.4"
      class="font-medium"
      mb="12px"
    >
      <slot name="title">
        {{ t('merchantWallet.label.seedPhrase') }}
      </slot>
    </AppText>
    <div class="words-container">
      <template v-for="(word, i) in mnemonicArray" :key="i">
        <SplitInput
          v-if="word.shouldRepeat"
          v-model="word.repeat.value"
          mask="onlyChars"
          :template="'*'.repeat(word.item.length)"
          type="seed"
          :is-auto-focus-disabled="isAutoFocusDisabled ? true : !toValue(word.isFocused)"
          is-continuous-focus
          :state="mnemonicHasError ? 'error' : ''"
          hide-error-message
        />
        <div v-else class="word-item font-mono">
          {{ word.item }}
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { ref, toValue } from 'vue';

import SplitInput from '@/components/Inputs/SplitInput.vue';

import { randomInteger } from '@/utils/functions';
import { focusFirstElement } from '@/utils/focus';

const props = defineProps({
  mnemonic: {
    type: String,
    default: '',
  },
  isAutoFocusDisabled: {
    type: Boolean,
    default: false,
  },
});

const sequenseStartIndex = randomInteger(1, props.mnemonic.split(' ').length - 7);
const mnemonicPart = props.mnemonic.split(' ').splice(sequenseStartIndex, 6);

const indexes = [];

indexes[0] = randomInteger(0, 5);
indexes[1] = randomInteger(0, 5, indexes[0]);

const mnemonicArray = mnemonicPart.map((word, index) => ({
  item: word,
  shouldRepeat: indexes.includes(index),
  repeat: ref(indexes.includes(index) ? '' : word),
  element: ref(null),
  isFocused: ref(false),
}));

mnemonicArray.find((element) => element.shouldRepeat).isFocused.value = true;

const mnemonicHasError = ref(false);

const onValidate = () => {
  const isValid = mnemonicArray.every((word) => word.item === word.repeat.value);
  mnemonicHasError.value = !isValid;

  if (mnemonicHasError.value) {
    setTimeout(() => {
      mnemonicArray.forEach((data, index) => {
        if (indexes.includes(index)) {
          data.repeat.value = '';
        }
      });

      mnemonicHasError.value = false;
      focusFirstElement('.split-code-input');
    }, 2000);
    return false;
  }
  return true;
};

defineExpose({ onValidate });
</script>

<style scoped lang="scss">
.words-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 20px;
  margin-bottom: 30px;
}

.word-item {
  background: #F7F7F7;
  border-radius: 12px;
  @include flex-center;
  padding: 17px;
  min-width: 180px;
  height: 50px;
  font-size: 16px;
}
</style>
