<template>
  <div class="extra-coins" :class="{ 'common-wrapper': !isControl, 'control-wrapper': isControl }">
    <ExtraCoinsRow :model-value="isERC20CoinsSelected" :coins="ERC20Coins" :is-control="isControl" @toggle="onToggle" />
    <ExtraCoinsRow :model-value="isTRC20CoinsSelected" :coins="TRC20Coins" :is-control="isControl" @toggle="onToggle" />
    <ExtraCoinsRow :model-value="isBEP20CoinsSelected" :coins="BEP20Coins" :is-control="isControl" @toggle="onToggle" />
  </div>
</template>

<script setup>
import { computed } from 'vue';

import { fullCoinsArray } from '@/common/data';
import { useTemporaryStore } from '@/store';

import ExtraCoinsRow from './ExtraCoinsRow.vue';

defineProps({
  isControl: {
    type: Boolean,
    default: false,
  },
});

const { importWallets, store } = useTemporaryStore();

const ERC20Coins = fullCoinsArray.filter((coin) => coin.family === 'ERC-20' && coin.name !== 'Ethereum');
const TRC20Coins = fullCoinsArray.filter((coin) => coin.family === 'TRC-20');
const BEP20Coins = fullCoinsArray.filter((coin) => coin.family === 'BEP-20');

const isERC20CoinsSelected = computed(() => importWallets.value.some((item) => item === 'ERC-20'));
const isTRC20CoinsSelected = computed(() => importWallets.value.some((item) => item === 'TRC-20'));
const isBEP20CoinsSelected = computed(() => importWallets.value.some((item) => item === 'BEP-20'));

const onToggle = (value, isActive) => {
  store.updateImportWallets(value, isActive);
};
</script>

<style lang="scss" scoped>
.extra-coins {
  &.common-wrapper {
    max-width: 590px;
    padding: 0 20px;
    border: 1px solid var(--color-black-01);
    border-radius: 8px;

    :deep(.extra-coins-row) {
      border-bottom: 1px solid var(--color-black-01);

      &:last-child {
        border-bottom: none;
      }
    }
  }

  &.control-wrapper {
    max-width: 685px;
    :deep(.extra-coins-row) {
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 20px;
      }
    }
  }
}
</style>
